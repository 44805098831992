@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

@import 'ag-grid-community/styles/ag-grid.min.css';
@import 'ag-grid-community/styles/ag-theme-balham.min.css';
@import '@bryntum/schedulerpro/schedulerpro.material.min.css';
@import '@bryntum/taskboard/taskboard.material.css';
@import 'jsoneditor/dist/jsoneditor.min.css';
@import 'quill/dist/quill.snow.css';

textarea.jsoneditor-text {
  min-height: 900px;
}

.json-editor-container {
  height: 900px;
}

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();

  // ::-webkit-scrollbar {
  //   width: nb-theme(scrollbar-width) !important;
  //   height: nb-theme(scrollbar-width) !important;
  // }

  // ::-webkit-scrollbar {
  //   width: 15px !important;
  //   height: px !important;
  // }

  // ::-webkit-scrollbar-thumb {
  //   background: nb-theme(scrollbar-color) !important;
  //   border-radius: nb-theme(scrollbar-width) / 2 !important;
  // }

  // ::-webkit-scrollbar-track {
  //   background: nb-theme(scrollbar-background-color);
  // }

  ::-webkit-scrollbar {
    width: 23px;
    height: 23px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}

:root {
  --ion-background-color: var(--next-bg);
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

.pswp__dynamic-caption {
  color: #fff;
  width: 100%;
}
.pswp__dynamic-caption a {
  color: #fff;
  text-decoration: underline;
}

nb-stepper {
  --stepper-step-content-padding: 0px;
}

nb-card {
  --card-margin-bottom: 0px;
}

nb-chat-message-text {
  margin-top: 15px;
}

nb-chat {
  .header {
    padding: 0px;
  }
}

.chat-window {
  nb-card {
    max-width: 360px;

    nb-card-body {
      padding: 0px;
      height: 500px;
    }
    nb-card-header {
      padding: 0px;
      padding-left: 15px;
    }
  }
}

.newEMRWindow {
  nb-card {
    min-width: 760px;

    nb-card-body {
      padding: 20px;
      background-color: #edf1f7;
      height: 500px;
    }

    nb-card-header {
      padding: 0px;
      padding-left: 15px;
    }
  }
}

.ag-theme-material {
  font-size: 12px !important;
}

.ag-theme-balham {
  .ag-header {
    background-color: white;
  }
  .ag-header-cell-text {
    font-weight: bold;
  }
  .ag-root-wrapper {
    border: none;
  }
}

.pswp img {
  max-width: none;
  object-fit: contain;
}

.pswp-gallery__item {
  margin: 0 4px 4px 0;
}
.pswp-gallery__item img {
  display: block;
}
.pswp-gallery {
  max-width: 650px;
  padding: 0 50px 50px;
  background: #eee;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.chatbox {
  width: 1050px !important;
}

.markerStart {
  background-image: url('/assets/images/icon1.png');
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.markerEnd {
  background-image: url('/assets/images/icon1.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

nb-dialog-container {
  overflow: hidden auto !important;
  max-height: 95vh !important;
}

// Align contents of normal grid cells to the top
.b-grid-cell:not(.b-group-title)[data-column='name'] {
  align-items: start;
}

.b-grid-vertical-scroller {
  margin-left: -10px !important;
}

.b-grid-cell {
  padding-left: 20px;
}

.b-sch-event-content {
  flex-direction: column;
}

.b-sch-event-header {
  padding: 1.5em 0 0;
}

.b-sch-event-footer {
  padding: 0.5em 0 0;
  margin-top: 0;
}

// Customized event look
.b-sch-event {
  overflow: hidden;
  border-radius: 3px;
  align-items: start;
  padding-top: 0.5em;
  width: calc(100%);

  .b-sch-event-content {
    flex: 1;
  }
}

// Custom event content styling
.workOrderEvent {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr auto;
}

.workOrderEvent {
  font-weight: 500;
  padding: 0px;
  i {
    grid-column: 3;
    grid-row: 1;
  }
  .workOrderNum {
    font-size: 0.92em;
    margin: 0px;
    grid-column: 1;
    grid-row: 1;
    i.b-icon {
      margin-inline-end: 0.5em;
    }
  }
  .desc {
    grid-column: 1;
    grid-row: 2;
  }

  .chips {
    grid-column: 1;
    grid-row: 3;
    vertical-align: top;
    display: flex;
    flex-wrap: nowrap;

    margin-top: 5px;
    margin-bottom: 5px;
  }

  .statusChip {
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 1em;
    padding: 0.25em 0.75em;
    background-color: #ffffff;
    border-radius: 0.2em;
    margin-right: 4px;
    color: rgb(71, 71, 71) !important;
    border-width: 1px;
    font-weight: 700;
    height: 19px;
  }

  .workOrderChip {
    display: inline-block;
    font-size: 11px;
    border-radius: 2px;
    line-height: 1em;
    padding: 0.25em 0.75em;
    background-color: #d4d4d4;
    border-radius: 0.2em;
    margin-right: 4px;
    color: rgb(71, 71, 71) !important;
    border: solid #828282;
    border-width: 1px;
    font-weight: 700;
    height: 19px;
  }

  .dangerChip {
    display: inline-block;
    font-size: 11px;
    line-height: 1em;
    padding: 0.25em 0.75em;
    background-color: #ed576b;
    border-radius: 0.2em;
    margin-right: 4px;
    color: rgb(255, 255, 255);
    border: solid #ed576b;
    border-width: 1px;
    font-weight: 700;
    height: 19px;
  }

  .typeChip {
    display: inline-block;
    font-size: 11px;
    line-height: 1em;
    padding: 0.25em 0.75em;
    background-color: #4c8dff;
    border-radius: 0.2em;
    margin-right: 4px;
    color: rgb(255, 255, 255);
    border: solid #828282;
    font-weight: 700;
    border-width: 1px;
    height: 19px;
  }
}

.title {
  font-weight: 400;
}

.assigned,
.event-duration {
  font-size: 0.9em;
  font-weight: 300;
}

.event-duration {
  grid-column: 2;
  grid-row: 1;
}
// Custom employee info
.employee {
  flex: 1;
  display: grid;
  align-items: center;
  column-gap: 6.5px;

  row-gap: 0.4em;
  grid-template-columns: auto 1fr auto;

  .avatar {
    width: 40px;
    height: 40px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    font-weight: bold;
    color: rgb(37, 99, 235);
    text-transform: uppercase;
    background-color: rgb(219, 233, 254);
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 100%;
    max-width: 3em;
    grid-column: 1;
    grid-row: 1 / 3;
    margin-right: 3px;
    padding-top: 1px;
    padding-left: 1px;
  }

  /* Add this class when there's an image */
  .avatar.has-image {
    padding: 2px;
    background-color: white;
    border: 2px solid #4c8dff;
  }

  .avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .chip {
    font-size: 0.6rem;
    padding: 0.26em 0.55em 0.1rem 0.65em;
    font-family: Arial, sans-serif;
    /* font-weight: bold; */
    color: rgb(126, 126, 126);
    text-transform: uppercase;
    background-color: rgb(220, 220, 220);
    border-radius: 10px;
    margin-left: 4px;
    font-weight: 500;
  }

  .name {
    grid-column: 2;
    font-size: 1.1em;
    font-weight: 500;
    color: rgb(109, 109, 109);
    display: flex;
    align-items: center;
  }

  .role {
    grid-column: 2;
    font-size: 0.9em;
  }

  .team {
    grid-column: 2;
    font-size: 0.9em;
  }

  .employee-spacer {
    flex-grow: 1;
  }

  .employee-icon-container {
    display: flex;
    grid-column: 3;
  }
  // Assignments info
  .email,
  .msg {
    grid-column: 3;
    color: #a8a8a8;
    font-size: 0.9em;

    cursor: pointer;
    &::before {
      margin-inline-end: 0.2em;
    }

    &::after {
      content: '';
    }

    &:hover {
      color: rgb(37, 99, 235);
      transform: scale(1.2);
      transition: all 0.3s;
    }
  }

  .employee-kpi-container {
    grid-column: 2;
    display: flex;
    align-items: center;
    .timeSheetKPI,
    .workOrderKPI,
    .serviceVisitKPI {
      align-items: center;
      //background: #0d4bffd9;
      //color: grey;
      color: rgb(159, 159, 159);
      //color: white;
      border-radius: 5px;
      font-size: 0.85rem;
      margin-inline-end: 0.85em;
      font-weight: 480;

      cursor: pointer;
      &::before {
        margin-right: 3px;
        font-size: 0.8rem;
      }

      &:hover {
        color: rgb(37, 99, 235);
        transform: scale(1.2);
        transition: all 0.3s;
      }
    }
  }

  .msg {
    grid-row: 1;
  }

  .email {
    grid-row: 2;
  }
}

.employee-compact {
  @extend .employee;
  flex: 1;
  display: grid;
  align-items: center;
  justify-content: space-between;
  row-gap: 0.1em;

  .avatar {
    width: 30px;
    height: 30px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 0.85rem;
    color: rgb(37, 99, 235);
    text-transform: uppercase;
    background-color: rgb(219, 233, 254);
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 100%;
    max-width: 3em;
    grid-column: 1;
    grid-row: 1 / 3;
    margin-right: 3px;
    padding-top: 1px;
    padding-left: 1px;
  }

  .name {
    grid-column: 2;
    font-size: 0.95em;
    font-weight: 500;
    color: rgb(109, 109, 109);
  }

  .team {
    grid-column: 2;
    font-weight: 400;
    font-size: 0.9em;
  }

  .kpi-container {
    grid-column: 2;
    display: flex;
    gap: 12px;
    margin-top: 2px;
    font-size: 0.9em;
    color: rgb(109, 109, 109);
  }

  .kpi-item {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .kpi-value {
    margin-left: 2px;
  }
}

.marker {
  width: 0;
  height: 0;
}

.marker .location {
  display: flex;
  font-size: 2em;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  color: #fff;
  background: var(--marker-color);
  border: solid 2px;
  box-shadow: 0 0 4px #000;
  cursor: pointer;
  animation: var(--marker-animation) 2s infinite;
}

.marker .userLocation {
  display: flex;
  font-size: 1.5em;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  color: #fff;
  background: var(--marker-color);
  border: solid 2px;
  box-shadow: 0 0 4px #000;
  cursor: pointer;
  animation: var(--marker-animation) 2s infinite;
}

.marker .userLocation-compact {
  @extend .userLocation;
  font-size: 1.25em;
  width: 26px;
  height: 26px;
  display: flex;
  position: relative;
  top: 3px;
  right: 4px;

  .rotateIcon {
    @extend .rotateIcon;
    font-size: 2em;
  }
}

.rotateIcon {
  transform-origin: 10 10 !important;
  transform: rotateZ(-226deg);
}

.marker span {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  color: #fff;
  background: var(--marker-color);
  border: solid 2px;
  border-radius: 0 70% 70%;
  box-shadow: 0 0 4px #000;
  cursor: pointer;
  transform-origin: 0 0;
  transform: rotateZ(-135deg);
  animation: var(--marker-animation) 2s infinite;

  .txt {
    transform: rotateZ(135deg);
  }
}

.marker div {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 90px;
  height: 90px;
  color: #fff;
  background: var(--marker-color);
  border: solid 2px;
  border-radius: 100%;
  box-shadow: 0 0 4px #000;
  cursor: pointer;
  transform: rotateZ(-135deg);
  animation: var(--marker-animation) 2s infinite;
}

@keyframes pulse4 {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    transform: rotateZ(-135deg);
  }

  70% {
    transform: scale(1.5);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
    transform: rotateZ(-135deg);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transform: rotateZ(-135deg);
  }
}
.dot {
  height: 6px;
  width: 6px;
  background-color: rgb(255, 0, 0);
  border-radius: 50%;
  margin-bottom: 1px;
  display: inline-block;
  animation: pulse4;
}

div.b-sch-event-wrap.b-milestone-wrap {
  &.WorkOrderChangeLog .b-sch-event-content {
    transform: translateY(7px);
  }

  &.TimeSheet {
    .b-sch-event-content {
      background-color: red;
    }
  }

  .b-sch-event .b-fa {
    color: #ffffff;
  }

  &.LastTimeSheet {
    .b-sch-event-content {
      margin-left: 0px;
      margin-top: -16px;
      background-color: transparent !important;
      i {
        margin-right: 0em;
        padding-left: -20px;
      }
    }

    .b-sch-event-content::before {
      border: none !important;
    }

    .b-sch-event .b-fa {
      color: #ffffff;
    }
  }

  .b-sch-event {
    .label {
      font-size: 0.98em;
      color: #727272;
      font-weight: 600;
      position: absolute;
    }

    .label.top {
      transform: translate(29px, 2px);
    }

    .label.top.compact {
      transform: translate(24px, 0px);
      font-size: 1.9em;
      display: none;
    }

    .label.bottom {
      transform: translate(29px, 12px);
    }

    .label.bottom.compact {
      font-size: 2em;
      transform: translate(24px, 8px);
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
}

dd,
h1,
h2,
h3,
h4,
h5,
h6,
legend {
  margin-bottom: 3px !important;
}

.b-stickyevents.b-sch-horizontal
  .b-sch-event-wrap:not(.b-disable-sticky, .b-milestone-wrap)
  .b-sch-event,
.b-stickyevents.b-sch-horizontal .b-sch-resourcetimerange .b-sch-event {
  overflow: hidden;
}

.b-sch-event-wrap .b-sch-event:not(.b-milestone) .b-sch-event-content {
  width: 100%;
  font-size: 14px;
  flex-direction: column;
  overflow: visible;
}

.assigned {
  overflow: visible !important;
  margin-top: 0.3em;
}
.avatar-img {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  border: 1px solid;
  border-color: inherit;
  margin-top: -2px;
  margin-left: 3px;
  margin-inline-end: 0.3em;
  transition: transform 0.2s;
}
.avatar-img:hover {
  transform: scale(2);
  z-index: 1;
}

.b-sch-event * {
  border-color: inherit;
}

.b-sch-nonworkingtime.factoryShutdown {
  background: transparent
    repeating-linear-gradient(
      -55deg,
      rgba(221, 221, 221, 0.6),
      rgba(221, 221, 221, 0.6) 10px,
      rgba(238, 238, 238, 0.6) 5px,
      rgba(238, 238, 238, 0.6) 20px
    );
}
.b-grid-header .b-sch-nonworkingtime.factoryShutdown {
  opacity: 0.7;
}

.b-sch-horizontal .b-sch-event-wrap .b-sch-event-buffer .b-buffer-label:before {
  font-family: 'Font Awesome 6 Free', FontAwesome6Free;
  content: '\f1b9';
  margin-inline-end: 0.3em;
}
.b-sch-horizontal
  .b-sch-event-wrap
  .b-sch-event-buffer-before:not(.b-buffer-thin) {
  border-inline-start: 3px solid #ccc;
  margin-top: 15px !important;
}
.b-sch-horizontal
  .b-sch-event-wrap
  .b-sch-event-buffer-after:not(.b-buffer-thin) {
  border-inline-end: 3px solid #ccc;
}
.b-sch-horizontal .b-sch-event-wrap .event-duration {
  margin-top: 0.3em;
}
.b-sch-horizontal .b-sch-event-wrap .b-sch-event:not(.b-milestone) .b-fa {
  margin-inline-end: 0.75em;
}

.b-sch-vertical
  .b-sch-event-wrap
  .b-sch-event-buffer-before:not(.b-buffer-thin) {
  border-top: 3px solid #ccc;
}
.b-sch-vertical
  .b-sch-event-wrap
  .b-sch-event-buffer-after:not(.b-buffer-thin) {
  border-bottom: 3px solid #ccc;
}

.event-name {
  font-weight: bold;
}

.resource-image {
  width: 4em;
  height: 4em;
  border-radius: 100%;
  object-fit: cover;
}

.event-tooltip-resource-image {
  width: 3em;
  height: 3em;
  border-radius: 100%;
  object-fit: cover;
}

.b-sch-event-tooltip {
  min-width: auto;
  background-color: white !important;
}
.b-sch-event-tooltip header {
  display: flex;
  align-items: center;
}
.b-sch-event-tooltip header .resource-info {
  display: flex;
  flex-direction: row;
}
.b-sch-event-tooltip header .resource-name {
  font-size: 1.3em;
}
.b-sch-event-tooltip header .resource-role {
  color: #bbb;
  font-size: 0.9em;
}
.event-info {
  display: flex;
  margin-top: 10px;
}
.event-info strong {
  margin: 0 0 0.3em 0;
}
.event-info strong:not(:first-child) {
  margin-top: 1.5em;
}
.event-details {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
#eventmap {
  flex: 1;
  flex-direction: row;

  text-align: center;
  img {
    border-radius: 5px;
    width: 100%;
  }

  .address {
    font-size: 0.95em;
    font-weight: 450;
  }
  .latlng {
    margin-top: 8px;
    font-size: 0.9em;
    color: #cecece;
  }
}
.b-sch-event-tooltip #eventmap .leaflet-control-attribution {
  font-size: 10px;
}
.b-sch-event-tooltip i.b-icon {
  margin-inline-end: 0.5em;
}

.b-sch-event-tooltip i.b-icon.b-fa-arrow-left,
.b-sch-event-tooltip i.b-icon.b-fa-arrow-right {
  margin: 0 0 0 0.5em;
}

.b-tooltip .b-fa-car {
  margin-inline-end: 0.4em;
}

.b-sch-event-tooltip .avatars {
  display: inline-flex;
  flex-direction: row-reverse;
}

.b-sch-event-tooltip .avatar {
  position: relative;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 30px;
  height: 30px;
}

.b-sch-event-tooltip .avatar:not(:last-child) {
  margin-left: -60px;
}

.b-sch-event-tooltip .avatar img {
  display: block;
  width: 30px;
}

.detail-auto-window:not(.minimized) > nb-card {
  max-width: 85vw !important;
}

.detail-auto-window.minimized > nb-card {
  height: 100%;
  width: 100%;
}

.detail-window:not(.minimized) > nb-card {
  height: 85vh !important;
  width: 85vw !important;
}

.detail-window.minimized > nb-card {
  height: 100%;
  width: 100%;
}

nb-windows-container {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: 100%;
  background: #92929241 !important;
  padding-top: 4px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

nb-window {
  margin: 0px 0.5rem !important;
}

nb-window > nb-card:first-of-type > nb-card-header {
  padding: 0px;
  padding-left: 15px;
  font-weight: bold !important;
}

.detail-window > nb-card:first-of-type > nb-card-body {
  padding: 10px 20px;
  background: #edf1f7;
}

.detail-auto-window > nb-card:first-of-type > nb-card-body {
  padding: 10px 20px;
  background: #edf1f7;
}

nb-window.minimized > nb-card:first-of-type > nb-card-header {
  .title {
    max-width: 200px;
    margin-right: 0px !important;
  }

  .buttons {
    width: 0px !important;
  }
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  top: -3px !important;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 10px;
}

.anticon-check {
  top: -3px;
}

nb-window {
  overflow: hidden;
}

.b-taskboard-column-body-inner {
  padding: 10px;
  gap: 10px;
}

// .b-taskboardbase {

//   * {
//     background-image: none !important;
//   }

//   --taskboard-background: #fff;
//   --taskboard-column-gap: 0;
//   --taskboard-column-background: #f7f8f9;
//   --taskboard-column-header-background: #f3f4f5;
//   --taskboard-card-border-top-radius: 0;
//   --taskboard-card-border-bottom-radius: 0;

//   --border-color: #d8d9da;
//   --details-color: #aaa;
// }

.b-theme-classic .b-taskboardbase {
  --taskboard-column-header-background: #e0e0e7;
  --taskboard-column-background: #f0f0f7;
  --border-color: #b0b0b7;
}

.b-theme-classic-light .b-taskboardbase {
  --taskboard-column-header-background: #f9f9f9;
  --taskboard-column-background: #fcfcfc;
  --border-color: #e0e0e0;
}

.b-theme-classic-dark .b-taskboardbase {
  --taskboard-column-header-background: #38383c;
  --taskboard-column-background: #44444a;
  --border-color: #2b2b2f;
}

.b-theme-material .b-taskboardbase {
  --taskboard-column-header-background: #fafafa;
  --taskboard-column-background: #f9f9f9;
  --border-color: transparent;
}

.b-taskboard-column-drag-proxy .b-taskboard-column-header,
.b-taskboard-column-headers {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.b-taskboard-column-drag-proxy .b-taskboard-column-header {
  z-index: 1;
}

.b-taskboard-column-header {
  border-bottom: 1px solid var(--border-color);
}

.done {
  opacity: 0.4;
}

.details {
  display: grid;
  grid-template-columns: 6em 1fr;
  align-items: center;
  color: var(--taskboard-card-body-color);
}
.details label {
  color: var(--details-color);
}
.details div i {
  margin-inline-start: 0.5em;
}

.progress-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}
.progress-bar {
  background-color: #e0e0e0;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.progress-fill {
  background-color: #4caf50;
  height: 100%;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
  position: relative;
}
.progress-fill::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  overflow: hidden;
}
@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.json-viewer {
  background: #1e1e1e;
  color: #d4d4d4;
  padding: 0.5rem 0;
  border-radius: 0.375rem;
  font-family: 'Monaco', 'Menlo', monospace;
  font-size: 0.875rem;
  line-height: 1.5;
  tab-size: 2;
}

.json-line {
  position: relative;
  padding-left: 3.5rem;
}

.json-line:hover {
  background: #282828;
}

.json-line-number {
  position: absolute;
  left: 0;
  width: 3rem;
  color: #6b6b6b;
  text-align: right;
  padding-right: 1rem;
  user-select: none;
  font-family: 'Monaco', 'Menlo', monospace;
}

/* VS Code-like syntax highlighting */
.json-key {
  color: #9cdcfe;
} /* Light blue for keys */
.json-string {
  color: #ce9178;
} /* Orange-pink for strings */
.json-number {
  color: #b5cea8;
} /* Light green for numbers */
.json-boolean {
  color: #569cd6;
} /* Blue for booleans */
.json-null {
  color: #569cd6;
} /* Blue for null */
.json-bracket {
  color: #d4d4d4;
} /* Light gray for brackets/punctuation */

.grid-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 1.5rem;
  width: 100%;
}

.json-column {
  min-width: 0;
  width: 100%;
}

nb-tabset nb-tab {
  padding-left: 0px;
  padding-right: 0px;
  border-top: 1px solid rgb(212, 212, 212) !important;
}

.action-modal {
  ion-modal {
    --background: white;
  }
}

.part-line-edit {
  --height: auto;
}
